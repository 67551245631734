@use '~/ui/styles/typography.module';
@use '~/ui/styles/breakpoints.module';

.title {
  @include typography.sectionTitle;

  + .text,
  + .link {
    margin-top: 2.4rem;
  }
}

.text {
  + .link {
    margin-top: 3.2rem;
  }

  @media (min-width: breakpoints.$lg) {
    padding-right: 3.3rem;
  }
}

.textWithSpacing {
  @media (min-width: breakpoints.$md) {
    padding-right: 4rem;
  }
}

.link {
  display: inline-block;
}

.childrenWrapper {
  @media (max-width: breakpoints.$md - 1) {
    margin-top: 6.4rem;
  }
}
