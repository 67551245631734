@use '~/ui/styles/utils.module';
@use '~/ui/styles/color.module';

.image {
  overflow: hidden;
}

.root {
  width: 75%;
}

.imagePlaceholder {
  position: relative;
  aspect-ratio: 4/5;
  background-color: color-mix(in srgb, var(--theme-primary), #000 15%);
}

.imagePlaceholderIcon {
  position: absolute;
  right: 0;
  bottom: 11.6%;

  width: 71%;

  color: var(--theme-primary);
}

.image {
  height: auto !important;
}

.content {
  margin-block: 12px;
}

.caption {
  color: color.$caption;
}

.ctaLabel {
  @include utils.sr-only;
}
