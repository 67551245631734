@use '~/ui/styles/breakpoints.module';
@use '~/ui/styles/utils.module';

.card {
  @include utils.focus(-4px);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  max-width: 19.2rem;
  min-height: 36.8rem;
  padding: 2.4rem 2.4rem 4rem;

  color: var(--theme-copy);

  @media (min-width: breakpoints.$md) {
    min-height: 40.3rem;
  }

  @media (min-width: breakpoints.$lg) {
    max-width: 17.6rem;
    min-height: 47.4rem;
    padding: 4rem 4rem 4.8rem;
  }
}

.label {
  margin-bottom: 2.4rem;
}
