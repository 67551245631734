@use '/styles/base.scss' as *;

.root {
  @include md {
    padding: 8.8rem $margin-md 3.2rem;
  }

  @include lg {
    padding: 12rem 6.4rem 3.2rem;
  }

  overflow: hidden;
  padding: 6.4rem $margin-sm 2.2rem;
  color: var(--theme-copy);
  background-color: var(--theme-primary);
}

.staticIdeas {
  @include lg {
    flex-direction: row;
    flex-wrap: wrap;
  }

  display: flex;
  flex-direction: column;
  word-break: break-word;
  list-style-type: none;
}

.ideas,
.newsletter {
  @include md {
    margin-bottom: 12rem;
  }

  margin-bottom: 8.8rem;
}

.title {
  @include lg {
    margin-bottom: 6rem;
  }

  margin-bottom: 4rem;
}

.bottomNav {
  list-style-type: none;
}

.newsletterWrapper {
  display: grid;
  width: 100%;

  .newsletter {
    @include lg {
      justify-self: flex-end;
      width: 40%;
    }

    padding: 0;
    background: var(--theme-primary);
  }
}

.linksWrapper {
  @include md {
    margin-top: 3.2rem;
  }

  @include lg {
    flex-direction: row;
    align-items: center;
    margin-top: 6.4rem;
  }

  display: flex;
  flex-direction: column;
  margin-top: 6.4rem;
}

.iconsWrapper {
  @include md-only {
    width: 100%;
  }

  @include md {
    flex-direction: row;
    align-items: center;
  }

  @include sm-only {
    margin-top: 3.2rem;
  }

  @include lg {
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: space-between;
  }

  display: flex;
  flex-direction: column;
}

.footerLinks {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
}

.footerLinks > li {
  @include sm-only {
    flex-basis: 50%;
    flex-direction: column;
    align-items: start;
  }

  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  a {
    @include md {
      white-space: nowrap;
    }

    margin-right: 2.4rem;
  }
}

.line {
  @include lg {
    display: block;
    margin-right: 2.4rem;
    padding: 1rem 0;

    svg {
      height: 2rem;
    }
  }

  display: none;
}

.icons {
  @include sm-only {
    margin-top: 2.2rem;
  }

  @include md {
    margin-left: 2.4rem;
  }
}

.logo {
  @include lg {
    width: calc(100% - 20rem);
  }
}
