@use '~/ui/styles/breakpoints.module';
@use '~/ui/styles/color.module';
@use '~/ui/styles/typography.module';
@use '~/ui/styles/utils.module';
@use '~/ui/modules/layout/navigation/navigation.constants.module' as navigation;

$navigation-height-sm: 25.3rem;
$navigation-height-md: 32.8rem;

.spotlight,
.spotlightInner {
  position: relative;
}

.spotlight {
  background: color.$white;

  @media (min-width: breakpoints.$md) {
    height: 100%;
    max-height: calc(100vh - #{navigation.$primary-sticky-tablet-height});
  }

  @media (min-width: breakpoints.$lg) {
    max-height: 100vh;
  }
}

.spotlightInner {
  height: 100%;
}

.content {
  box-sizing: border-box;
  width: 100%;
  margin-inline: 0;
  padding: 2.4rem 2rem 4rem;

  background-color: rgba(var(--theme-primary-rgb), 0.25);

  @media (min-width: breakpoints.$md) {
    position: absolute;
    bottom: 0;

    display: flex;
    align-items: flex-end;

    height: 100%;
    padding: 27.5rem 0 4.4rem;

    color: color.$white;

    background: linear-gradient(to top, rgba(0, 0, 0, 0.55), transparent);
  }

  @media (min-width: breakpoints.$lg) {
    padding: 27.5rem 0 4rem;
  }
}

.gradient {
  position: absolute;
  bottom: 0;

  width: 100%;
  height: 27.5rem;

  background: linear-gradient(to top, rgba(0, 0, 0, 0.55), transparent);

  @media (min-width: breakpoints.$md) {
    display: none;
  }
}

.eyebrow {
  @include typography.bodySmall;

  margin-bottom: 1.2rem;

  @media (min-width: breakpoints.$md) {
    @include typography.bodyLarge;
  }

  @media (min-width: breakpoints.$lg) {
    margin-bottom: 1.6rem;
  }
}

.headline {
  margin-bottom: 2.4rem;
}

.icon {
  width: 0.85rem;
  height: 1.2rem;
  margin-right: 0.8rem;
}

.label {
  margin-right: 2.4rem;
}

.image {
  overflow: hidden;
  background-color: var(--theme-primary);

  img {
    object-fit: cover;
  }

  @media (min-width: breakpoints.$md) {
    height: 100%;
    max-height: calc(100vh - #{navigation.$primary-sticky-tablet-height});
  }

  @media (min-width: breakpoints.$lg) {
    max-height: 100vh;
  }
}

.imageZoomIn img {
  animation: zoomIn 12s ease forwards;
}

.imageZoomOut img {
  animation: zoomOut 12s ease forwards;
}

@keyframes zoomIn {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.06);
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1.06);
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.button {
  @include utils.focus;

  position: absolute;
  z-index: 1;
  right: 2rem;
  bottom: 2rem;

  width: 4.4rem;
  height: 4.4rem;

  border: 0.1rem solid rgba(color.$white, 0.25);

  &:hover {
    border: 0.2rem solid rgba(color.$white, 0.25);
  }

  @media (min-width: breakpoints.$md) {
    right: 4rem;
    bottom: 4rem;
  }
}

.pauseButton {
  display: none;
}

.iconPlayPause {
  color: color.$white;
}

.icon,
.iconPlayPause {
  width: 2rem;
  height: 2rem;
}

.buttonInfo {
  margin-left: 2.4rem;
}

.spotlightFullHeight {
  @media (max-width: breakpoints.$md - 1) {
    display: flex;
    flex-direction: column;
    min-height: calc(100 * var(--vh) - $navigation-height-sm);

    > .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .spotlightInner {
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 100%;
    }

    .image {
      flex: 1;
    }

    .previewVideo {
      position: relative;
      overflow: hidden;
      flex: 1;
      width: 100vw;
    }
  }

  @media (min-width: breakpoints.$md) {
    overflow: hidden;
    height: calc(100 * var(--vh) - $navigation-height-md);

    @media (orientation: portrait) {
      .previewVideo {
        height: 100%;
      }
    }
  }

  @media (min-width: breakpoints.$lg) {
    height: calc(100 * var(--vh));

    .previewVideo {
      height: 100%;
    }
  }
}

.dialog {
  position: fixed;
  inset: 0;
  background-color: color.$black;
}

.dialogDismiss {
  position: absolute;
  z-index: 2;
  top: 2rem;
  right: 2rem;

  color: color.$white;

  background-color: color.$black;
}

.modalVideo > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
