@use 'sass:math';
@use '~/ui/styles/breakpoints.module';
@use '~/v1/components/navigation/navigation.constants.module.scss' as navigation;

.root {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - #{navigation.$primary-sticky-mobile-height});
  background-color: var(--theme-primary);

  @media (min-width: breakpoints.$md) {
    max-height: calc(100vh - #{navigation.$primary-sticky-tablet-height});

    &.emphasized.portrait {
      flex-direction: row;
    }
  }

  @media (min-width: breakpoints.$lg) {
    max-height: 100vh;
  }
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  padding: 24px 24px 40px;

  @media (min-width: breakpoints.$md) {
    .emphasized.portrait & {
      justify-content: flex-end;
      width: math.div(3, 7) * 100%;
    }
  }

  @media (min-width: breakpoints.$lg) {
    padding: 40px 40px 48px;
  }
}

.image[class] {
  overflow: hidden;
  width: 100%;
  height: unset;

  img {
    object-fit: cover;
  }

  @media (min-width: breakpoints.$md) {
    .emphasized.portrait & {
      width: math.div(4, 7) * 100%;
    }
  }
}

.logoContainer {
  align-content: center;
  width: 200px;
  height: 50px;
  margin-bottom: 24px;

  > * {
    // Safari doesn't respect the aspect ratio if a max-width is provided. Honestly, I'm stumped.
    // max-width: 100%;
    max-height: 100%;
  }

  // sizing specific for image logos
  > div {
    width: unset;
    height: unset;
  }

  @media (min-width: breakpoints.$lg) {
    margin-bottom: 40px;
  }
}

.textContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 40px;

  // if there is a secondary cta, we have more spacing between it and the text
  .root.hasSecondaryCta:has(.image) & {
    margin-bottom: 40px;

    @media (min-width: breakpoints.$md) {
      margin-bottom: 48px;
    }

    @media (min-width: breakpoints.$lg) {
      margin-bottom: 64px;
    }
  }

  // if there is an image and secondary cta but the card is emphasized, it stays at 24px no matter the breakpoint
  .root.emphasized.hasSecondaryCta:has(.image) & {
    margin-bottom: 24px;
  }

  .root:has(.image) & {
    margin-bottom: 24px;

    @media (min-width: breakpoints.$lg) {
      margin-bottom: 32px;
    }
  }

  @media (min-width: breakpoints.$lg) {
    margin-bottom: 64px;
  }
}

.ctaWrapper {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;

  .root:not(.emphasized.portrait) & {
    margin-top: auto;
  }
}
