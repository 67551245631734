@use '~/ui/styles/breakpoints.module';

.root {
  margin-block: 4.8rem;
  isolation: isolate;

  @media (min-width: breakpoints.$md) {
    margin-block: 8rem;
  }

  @media (min-width: breakpoints.$lg) {
    margin-block: 12rem;
  }
}

.bodyColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;

  + * {
    margin-top: 4.8rem;

    @media (min-width: breakpoints.$md) {
      margin-top: 8rem;
    }

    @media (min-width: breakpoints.$lg) {
      margin-top: 12rem;
    }
  }
}

.body {
  text-align: center;
  text-wrap: balance;
  padding-inline: 20px;
}

.imageColumn {
  z-index: 2;
}

.imageColumn > * + *,
.imageColumn + * {
  margin-top: 4.8rem;

  @media (min-width: breakpoints.$md) {
    margin-top: 8rem;
  }

  @media (min-width: breakpoints.$lg) {
    margin-top: 12rem;
  }
}

.image {
  height: auto;
}
