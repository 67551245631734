@use '~/ui/styles/color.module';
@use '~/ui/styles/utils.module';

@value icon from '~/v1/components/expandingArrow/expandingArrow.module.scss';

.root {
  position: relative;

  img,
  svg {
    transition: transform 0.3s;
  }

  /*  */
  *:has(> img),
  *:has(> svg),
  .trigger {
    /**
     * Animated elements break out of overflow:hidden unless they have their own stacking context.
     * https://stackoverflow.com/a/58283449
     */
    isolation: isolate;
  }

  &:has(.trigger:hover),
  &:has(.trigger:focus-visible) {
    img,
    // dont scale expanding arrows (which have their own animation)
    svg:not(.icon) {
      transform: scale(1.03);
    }
  }

  &:has(.trigger:focus-visible) {
    @include utils.focus-base(0);
  }
}

.trigger {
  position: unset;
  outline: none;

  &::after {
    content: '';
    position: absolute;
    z-index: 5;
    inset: 0;
  }
}
