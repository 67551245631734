.truncate1,
.truncate3,
.truncate4,
.truncate6 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.truncate1 {
  -webkit-line-clamp: 1;
}

.truncate3 {
  -webkit-line-clamp: 3;
}

.truncate4 {
  -webkit-line-clamp: 4;
}

.truncate6 {
  -webkit-line-clamp: 6;
}
