@use '~/ui/styles/breakpoints.module';
@use '~/ui/styles/grid.module';

.section {
  margin-bottom: 4.8rem;

  @media (min-width: breakpoints.$md) {
    margin-bottom: 6.4rem;
  }
}

.socialIcons {
  display: flex;
}

.title,
.text {
  margin-bottom: 2.4rem;
}

.label {
  margin-bottom: 0.4rem;
}

.text {
  display: block;
}

.link {
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
  padding: 0 !important;

  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 0.4rem;
  }
}

.email {
  margin-bottom: 2.4rem;

  button {
    padding: 0;
  }
}

.officeSection {
  @media (max-width: breakpoints.$md - 1) {
    flex-direction: column-reverse !important;
  }
}

.quickLinks {
  @media (max-width: breakpoints.$md - 1) {
    margin-bottom: 4.8rem;
  }
}

.newsletter {
  margin-left: -1 * grid.$margin-sm;

  @media (min-width: breakpoints.$md) {
    margin-left: 0;
  }
}
