@use '~/ui/styles/breakpoints.module';
@use '~/ui/styles/utils.module';
@use '~/ui/styles/spacing.module';

.card {
  @include utils.focus;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  padding: 2.4rem 2.4rem 4rem;

  color: var(--theme-copy);

  background-color: var(--theme-primary);

  &:hover {
    color: var(--theme-copy);

    .link {
      color: rgba(var(--theme-copy-rgb), 0.65);
    }
  }

  @media (min-width: breakpoints.$lg) {
    padding: 4rem 4rem 4.8rem;
  }
}

.image {
  width: 9.6rem;

  img {
    object-fit: cover;
  }
}

.question {
  margin-top: 1.6rem;
  margin-bottom: 0.8rem;

  hyphens: auto;
  word-break: break-word;

  -webkit-hyphenate-limit-before: 6;
  -ms-hyphenate-limit-chars: 6;
  hyphenate-limit-chars: 8 6 2;

  @media (min-width: breakpoints.$lg) {
    margin-top: 2.4rem;
    margin-bottom: 1.6rem;
  }
}

.link {
  padding-top: spacing.$section-sm;

  @media (min-width: breakpoints.$md) {
    padding-top: spacing.$section-md;
  }

  @media (min-width: breakpoints.$lg) {
    padding-top: spacing.$section-lg;
  }
}
