@import '/styles/base.scss';

.grid {
  > div + div {
    margin-top: 2.4rem;

    @include md {
      margin-top: 0;
    }
  }
}

.label {
  margin-top: 1.6rem;
  color: $color-caption;

  &.leftAlign {
    margin-left: 2rem;
  }
}

.imageWrapper {
  & + & {
    margin-top: 2.4rem;

    @include md {
      margin-top: 0;
    }
  }
}

.imageWrapper:not(.twoUp) {
  & + & {
    @include md {
      margin-top: 6.4rem;
    }

    @include lg {
      margin-top: 8.8rem;
    }
  }
}

.image {
  height: auto;
}
