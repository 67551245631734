@use '~/ui/styles/breakpoints.module';
@use '~/ui/styles/utils.module';

.card {
  @include utils.focus;

  isolation: isolate;

  display: flex;
  flex-direction: column;

  padding: 2.4rem 2.4rem 4rem;

  color: var(--theme-copy);

  background-color: var(--theme-primary);

  @media (min-width: breakpoints.$lg) {
    padding: 4rem 4rem 4.8rem;
  }
}

.eyebrow {
  margin-bottom: 0.8rem;
}

.title {
  margin-bottom: 2.4rem;
}

.image,
.imagePlaceholder {
  overflow: hidden;

  aspect-ratio: 4/5;
  width: 96px;
  height: 120px;
  margin-bottom: 1.6rem;
}

.imagePlaceholder {
  position: relative;
  background-color: var(--theme-primary);

  &::before {
    content: '';

    display: block;

    width: 100%;
    height: 100%;
    // darken the placeholder;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15));
  }
}

.imagePlaceholderIcon {
  position: absolute;
  right: 0;
  bottom: 11.6%;

  width: 71%;

  color: var(--theme-primary);
}

.author {
  z-index: -1;
  margin-top: 8.8rem;

  &:has(.image, .imagePlaceholder) {
    margin-top: 6.4rem;
  }

  @media (min-width: breakpoints.$md) {
    margin-top: 8.8rem;
  }

  @media (min-width: breakpoints.$lg) {
    margin-top: 12rem;
  }
}
