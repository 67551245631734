@use '~/ui/styles/breakpoints.module';

.title {
  margin-bottom: 24px;
}

.grid {
  row-gap: 48px;

  @media (min-width: breakpoints.$md) {
    row-gap: 40px;
  }

  @media (min-width: breakpoints.$lg) {
    row-gap: 64px;
  }
}
