@use '~/ui/styles/breakpoints.module';
@use '~/ui/styles/color.module';

.grid {
  > div + div {
    margin-top: 2.4rem;

    @media (min-width: breakpoints.$md) {
      margin-top: 0;
    }
  }
}

.label {
  margin-top: 1.6rem;
  color: color.$caption;

  &.leftAlign {
    margin-left: 2rem;

    @media (min-width: breakpoints.$xl) {
      margin-left: 0;
    }
  }
}

.imageWrapper {
  & + & {
    margin-top: 2.4rem;

    @media (min-width: breakpoints.$md) {
      margin-top: 0;
    }
  }
}

.imageWrapper:not(.twoUp) {
  & + & {
    @media (min-width: breakpoints.$md) {
      margin-top: 6.4rem;
    }

    @media (min-width: breakpoints.$lg) {
      margin-top: 8.8rem;
    }
  }
}

.image {
  height: auto;
}
